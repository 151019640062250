import UserViewModel from '@/Model/UserViewModel';
import axios, { AxiosResponse } from 'axios';
import IComandResult from '@/Model/IComandResultTyped';
import useAuthToken from '@/utillities/useAuthToken';
import CustomerViewModel from '@/Model/CustomerViewModel';
export default (): any => {
  const getAll = async (): Promise<Array<UserViewModel>> => {
    await useAuthToken();
    return new Promise<Array<UserViewModel>>((resolve, reject) => {
      axios
        .get('/api/admin/users')
        .then(
          (response: AxiosResponse<IComandResult<Array<UserViewModel>>>) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const get = async (id: string): Promise<UserViewModel> => {
    await useAuthToken();
    return new Promise<UserViewModel>((resolve, reject) => {
      axios
        .get(`/api/admin/users/${id}`)
        .then((response: AxiosResponse<IComandResult<UserViewModel>>) => {
          if (response.status == 200) {
            if (response.data.success) {
              resolve(response.data.data);
            } else {
              reject(response.data.errors);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const search = async (query: string): Promise<Array<UserViewModel>> => {
    await useAuthToken();
    return new Promise<Array<UserViewModel>>((resolve, reject) => {
      axios
        .get(`/api/admin/users/search?filter=${query}`)
        .then(
          (response: AxiosResponse<IComandResult<Array<UserViewModel>>>) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getOld = async (): Promise<Array<UserViewModel>> => {
    await useAuthToken();
    return new Promise<Array<UserViewModel>>((resolve, reject) => {
      axios
        .get('/api/admin/oldusers')
        .then(
          (response: AxiosResponse<IComandResult<Array<UserViewModel>>>) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getLocations = async (
    userId: string
  ): Promise<Array<CustomerViewModel>> => {
    await useAuthToken();
    return new Promise<Array<CustomerViewModel>>((resolve, reject) => {
      axios
        .get(`/api/admin/users/${userId}/customer`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteUser = async (
    userId: string
  ) => {
    await useAuthToken();
    return new Promise<Array<CustomerViewModel>>((resolve, reject) => {
      axios
        .delete(`/api/admin/users/${userId}`)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.success) {
              resolve(response.data.data);
            } else {
              reject(response.data.errors);
            }
          }
        }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  return { getAll, getOld, search, get, getLocations, deleteUser };
};
