
import { computed, defineComponent, ref } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useUsers from './useUsers';
import { useRoute, useRouter } from 'vue-router';
import toDateString from '@/utillities/toDateString';
import CustomerViewModel from '@/Model/CustomerViewModel';
export default defineComponent({
  setup() {
    const { get, getLocations, deleteUser } = useUsers();
    const { params } = useRoute();
    const user = ref<UserViewModel>();
    const customers = ref<Array<CustomerViewModel>>();
    const groupCustomer = computed<Array<CustomerViewModel>>(() => {
      if (customers.value) {
        return customers.value?.filter(
          (item: CustomerViewModel) => item.customerType == 1
        );
      }
      return [];
    });
    const singleCustomer = computed<Array<CustomerViewModel>>(() => {
      if (customers.value) {
        return customers.value?.filter(
          (item: CustomerViewModel) => item.customerType == 0
        );
      }
      return [];
    });
    get(params.id).then((response: UserViewModel) => {
      user.value = response;
      getLocations(params.id).then((response: Array<CustomerViewModel>) => {
        customers.value = response;
      });
    });

    const router = useRouter();
    const confirmedDeletion = () => {
      return deleteUser(user.value?.id).then(() => router.go(-1));
    };

    return {
      user,
      deleteUser,
      toDateString,
      groupCustomer,
      singleCustomer,
      confirmedDeletion
    };
  }
});
