import CustomerViewModel from '@/Model/CustomerViewModel';
import axios, { AxiosResponse } from 'axios';
import IComandResult from '@/Model/IComandResultTyped';
import useAuthToken from '@/utillities/useAuthToken';
import CustomerUserViewModel from '@/Model/CustomerUserViewModel';
export default (): any => {
  const query = async (query: string): Promise<Array<CustomerViewModel>> => {
    await useAuthToken();
    const url =
      query.length > 0
        ? `/api/admin/customer?query=${query}`
        : '/api/admin/customer';
    return new Promise<Array<CustomerViewModel>>((resolve, reject) => {
      axios
        .get(url)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const get = async (id: number): Promise<CustomerViewModel> => {
    await useAuthToken();
    return new Promise<CustomerViewModel>((resolve, reject) => {
      axios
        .get(`/api/admin/customer/${id}`)
        .then((response: AxiosResponse<IComandResult<CustomerViewModel>>) => {
          if (response.status == 200) {
            if (response.data.success) {
              resolve(response.data.data);
            } else {
              reject(response.data.errors);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getLocations = async (
    id: number
  ): Promise<Array<CustomerViewModel>> => {
    await useAuthToken();
    return new Promise<Array<CustomerViewModel>>((resolve, reject) => {
      axios
        .get(`/api/admin/customer/${id}/locations`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getLocationForUser = async (
    userId: string,
    id: number
  ): Promise<Array<CustomerViewModel>> => {
    await useAuthToken();
    return new Promise<Array<CustomerViewModel>>((resolve, reject) => {
      axios
        .get(`/api/admin/users/${userId}/${id}`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getUsers = async (
    id: number
  ): Promise<Array<CustomerUserViewModel>> => {
    await useAuthToken();
    return new Promise<Array<CustomerUserViewModel>>((resolve, reject) => {
      axios
        .get(`/api/admin/customer/${id}/users`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerUserViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const addUser = async (
    id: string,
    customerid: number,
    locationIds: Array<number>,
    type: string
  ): Promise<void> => {
    await useAuthToken();
    return new Promise<void>((resolve, reject) => {
      const model = { type, customerid, locationIds };
      axios
        .post(`/api/admin/users/${id}/customer`, model)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerUserViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve();
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const removeUser = async (id: string, customerid: number) => {
    await useAuthToken();
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(`/api/admin/users/${id}/${customerid}`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerUserViewModel>>>
          ) => {
            if (response.status == 200) {
              resolve();
            } else {
              reject(response.data.errors);
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  return {
    query,
    get,
    getUsers,
    getLocations,
    addUser,
    removeUser,
    getLocationForUser
  };
};
