
import { defineComponent, ref, watch } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useUsers from './useUsers';
export default defineComponent({
  name: 'UserList',
  setup() {
    const { search } = useUsers();

    const filter = ref<string>('');
    const users = ref<Array<UserViewModel>>([]);
    let waitTimeOut: number = 500;

    const filterUser = (filter: string) => {
      search(filter).then((result: Array<UserViewModel>) => {
        users.value = result;
      });
    };
    watch(
      () => filter.value,
      () => {
        setTimeout(() => {
          filterUser(filter.value);
        }, waitTimeOut);
      }
    );
    filterUser('');
    return { users, filter };
  }
});
