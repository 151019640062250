
import { defineComponent, ref } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useUsers from './useUsers';
export default defineComponent({
  name: 'OldUserList',
  setup() {
    const { getOld } = useUsers();
    const users = ref<Array<UserViewModel>>([]);
    getOld().then((result: Array<UserViewModel>) => {
      users.value = result;
    });
    return { users };
  }
});
