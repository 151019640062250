<template>
  <div v-if="!model" class="spinner-grow" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div class="row" v-else>
    <div class="col-12 header text-start">
      <h4>
        <i class="bi bi-building"></i>
        <span class="pl-2">{{ model.name }}</span>
      </h4>
    </div>
    <div class="col-4">
      <form class="text-start">
        <div class="row mb-3">
          <label for="inputName" class="col-sm-3 col-form-label">Navn</label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputName"
              readonly
              placeholder="Example input placeholder"
              v-model="model.name"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label for="inputAdresse" class="col-sm-3 col-form-label">
            Adresse
          </label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputAdresse"
              placeholder="adresse"
              readonly
              v-model="model.address"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label for="inputLokation" class="col-sm-3 col-form-label">
            Lokation
          </label>
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              id="inputLokation"
              placeholder="lokation"
              readonly
              v-model="model.location"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label for="inputCity" class="col-sm-3 col-form-label">
            Postnr. og By
          </label>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              id="inputZip"
              placeholder="postnr"
              readonly
              v-model="model.zipCode"
            />
          </div>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="inputCity"
              placeholder="adresse"
              readonly
              v-model="model.city"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="col-8">
      <ul class="nav nav-pills nav-fill" id="customerTab" role="tablist">
        <li class="nav-item" v-if="model.customerType == 1">
          <a
            class="nav-link"
            :class="{ active: model.customerType == 1 }"
            data-bs-toggle="tab"
            data-bs-target="#location-tab"
            type="button"
            role="tab"
            aria-controls="location-tab"
            aria-selected="true"
            href="#"
          >
            Lokationer
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: model.customerType == 0 }"
            href="#"
            data-bs-toggle="tab"
            data-bs-target="#user-tab"
            role="tab"
            aria-controls="location-tab"
            aria-selected="true"
          >
            Brugere
          </a>
        </li>
      </ul>
      <div class="tab-content" id="customerTab">
        <div
          class="tab-pane fade"
          :class="{ 'active show': model.customerType == 1 }"
          id="location-tab"
          role="tabpanel"
          aria-labelledby="location-tab"
        >
          <Location :value="model.id"></Location>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'active show': model.customerType == 0 }"
          id="user-tab"
          role="tabpanel"
          aria-labelledby="user-tab"
        >
          <Users :value="model.id" :type="model.customerType"></Users>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineAsyncComponent, defineComponent, onMounted, ref } from 'vue';
import useCustomers from './useCustomers';
import { useRoute } from 'vue-router';
import CustomerViewModel from '@/Model/CustomerViewModel';
export default defineComponent({
  components: {
    Location: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerLokations.vue'
        )
    ),
    Users: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerUsers.vue'
        )
    )
  },
  setup() {
    const { get } = useCustomers();
    const { params } = useRoute();
    const model = ref<CustomerViewModel>();
    onMounted(() => {
      const id = parseInt(params.id.toString(), 10);
      get(id).then((response: CustomerViewModel) => {
        model.value = response;
      });
    });
    return {
      model
    };
  }
});
</script>
