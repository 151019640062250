<template>
  <div class="row">
    <div class="col-auto header text-start">
      <h4>
        <i class="bi bi-building"></i>
        <span class="pl-2">Administrer kunder</span>
      </h4>
    </div>
    <div class="col-6">
      <input
        type="text"
        v-model="filter"
        placeholder="Søg"
        class="form-control"
      />
    </div>
  </div>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <td style="width: 10px"></td>
          <td class="text-start">Sags nr.</td>
          <td class="w-25 text-start">Navn</td>
          <td class="w-50 text-start">Adresse</td>
          <td class="text-start">Postnr og by</td>
          <td style="width: 20px"></td>
        </tr>
      </thead>
      <tbody>
        <template v-for="customer in customers" :key="customer.id">
          <component
            :is="customer.customerType === 1 ? 'GroupRow' : 'SingleRow'"
            :value="customer"
          ></component>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent, defineAsyncComponent, ref, watch } from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';
import useCustomers from './useCustomers';

export default defineComponent({
  components: {
    GroupRow: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerGroupRow.vue'
        )
    ),
    SingleRow: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerSingleRow.vue'
        )
    )
  },

  setup() {
    const { query } = useCustomers();
    const filter = ref<string>('');
    const customers = ref<Array<CustomerViewModel>>([]);
    const filterCustomer = (filter: string) => {
      query(filter).then((result: Array<CustomerViewModel>) => {
        customers.value = result;
      });
    };
    let timer: number = 0;
    let waitTimeOut: number = 500;
    watch(
      () => filter.value,
      () => {
        timer = setTimeout(() => {
          filterCustomer(filter.value);
        }, waitTimeOut);
      }
    );
    filterCustomer('');
    return { customers, filter };
  }
});
</script>
