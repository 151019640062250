<template>
  <div class="row mb-3">
    <div class="col-auto header text-start">
      <h4>
        <i class="bi bi-people"></i>
        <span class="pl-2">Administrer brugere</span>
      </h4>
    </div>
    <div class="col-6">
      <input
        type="text"
        v-model="filter"
        placeholder="Søg"
        class="form-control"
      />
    </div>
    <div class="col text-end">
      <router-link class="btn btn-primary btn-sm" :to="{ name: 'oldusers' }">
        Gamle brugere
      </router-link>
    </div>
  </div>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <td class="text-start">Navn</td>
          <td class="text-start">Email</td>
          <td class="text-start">Virksomhed</td>
          <td>Accepteret betingelser</td>
        </tr>
      </thead>
      <tbody>
        <router-link
          v-for="user in users"
          :key="user.id"
          :to="{ name: 'userDetails', params: { id: user.id } }"
          custom
          v-slot="{ navigate }"
        >
          <tr @click="navigate">
            <td class="text-start">{{ user.firstname }} {{ user.lastname }}</td>
            <td class="text-start">
              <i
                class="bi bi-check-circle text-success"
                v-if="user.confirmedEmail"
              ></i>
              <i class="bi bi-dash-circle-fill text-danger" v-else></i>
              {{ user.email }}
            </td>
            <td class="text-start">{{ user.company }}</td>
            <td>
              <i
                class="bi bi-check-circle text-success"
                v-if="user.acceptConditions"
              ></i>
              <i class="bi bi-dash-circle-fill text-danger" v-else></i>
            </td>
          </tr>
        </router-link>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useUsers from './useUsers';
export default defineComponent({
  name: 'UserList',
  setup() {
    const { search } = useUsers();

    const filter = ref<string>('');
    const users = ref<Array<UserViewModel>>([]);
    let waitTimeOut: number = 500;

    const filterUser = (filter: string) => {
      search(filter).then((result: Array<UserViewModel>) => {
        users.value = result;
      });
    };
    watch(
      () => filter.value,
      () => {
        setTimeout(() => {
          filterUser(filter.value);
        }, waitTimeOut);
      }
    );
    filterUser('');
    return { users, filter };
  }
});
</script>
