<template>
  <div class="row mb-3">
    <div class="col-auto header text-start">
      <h4>
        <i class="bi bi-people"></i>
        <span class="pl-2">Gamle brugere</span>
      </h4>
    </div>
  </div>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <td class="text-start">Navn</td>
          <td class="text-start">Email</td>
          <td class="text-start">Virksomhed</td>
          <td>Accepteret betingelser</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td class="text-start">{{ user.firstname }} {{ user.lastname }}</td>
          <td class="text-start">
            <i
              class="bi bi-check-circle text-success"
              v-if="user.confirmedEmail"
            ></i>
            <i class="bi bi-dash-circle-fill text-danger" v-else></i>
            {{ user.email }}
          </td>
          <td class="text-start">{{ user.company }}</td>
          <td>
            <i
              class="bi bi-check-circle text-success"
              v-if="user.acceptConditions"
            ></i>
            <i class="bi bi-dash-circle-fill text-danger" v-else></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useUsers from './useUsers';
export default defineComponent({
  name: 'OldUserList',
  setup() {
    const { getOld } = useUsers();
    const users = ref<Array<UserViewModel>>([]);
    getOld().then((result: Array<UserViewModel>) => {
      users.value = result;
    });
    return { users };
  }
});
</script>
