
import { defineComponent, defineAsyncComponent, ref, watch } from 'vue';
import CustomerViewModel from '@/Model/CustomerViewModel';
import useCustomers from './useCustomers';

export default defineComponent({
  components: {
    GroupRow: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerGroupRow.vue'
        )
    ),
    SingleRow: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerSingleRow.vue'
        )
    )
  },

  setup() {
    const { query } = useCustomers();
    const filter = ref<string>('');
    const customers = ref<Array<CustomerViewModel>>([]);
    const filterCustomer = (filter: string) => {
      query(filter).then((result: Array<CustomerViewModel>) => {
        customers.value = result;
      });
    };
    let timer: number = 0;
    let waitTimeOut: number = 500;
    watch(
      () => filter.value,
      () => {
        timer = setTimeout(() => {
          filterCustomer(filter.value);
        }, waitTimeOut);
      }
    );
    filterCustomer('');
    return { customers, filter };
  }
});
