<template>
  <div class="row pt-3" v-if="user">
    <div class="col-md-3">
      <h4 class="text-secondary">{{ user.firstname }} {{ user.lastname }}</h4>
      <h6>
        {{ user.email }}
      </h6>
      <p>{{ user.company }}</p>
      <p>
        Modtager emails
        <i class="bi bi-check-circle text-success" v-if="user.receiveEmail"></i>
        <i class="bi bi-dash-circle-fill text-danger" v-else></i>
        <br />
        <template v-if="user.lastLoginTime">
          Sidste login: {{ toDateString(user.lastLoginTime) }}
        </template>
      </p>
      <button
        type="button"
        class="btn btn-danger"
        data-bs-toggle="modal"
        data-bs-target="#deleteUserModal"
      >
        Fjern bruger
      </button>

      <!-- Modal -->
      <div
        class="modal fade"
        id="deleteUserModal"
        tabindex="-1"
        aria-labelledby="deleteUserModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteUserModalLabel">
                Fjern bruger
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Bekræft venligst at du ønsker at fjerne brugeren
              <b>{{ user.firstname }} {{ user.lastname }} ({{ user.email }})</b>
              .
              <br />
              <br />
              Dette kan ikke fortrydes!
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Annuller
              </button>
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                @click="confirmedDeletion"
              >
                Fjern
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div id="customers">
        <h4>Kunder</h4>
        <table
          class="table table-sm table-hover"
          v-if="groupCustomer && groupCustomer.length > 0"
        >
          <thead>
            <tr>
              <td class="text-start">Sags nr.</td>
              <td class="text-start">Navn</td>
              <td class="text-start">Adresse</td>
              <td class="text-start">Postnr og by</td>
              <td class="text-center">Type</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in groupCustomer" :key="customer.id">
              <td class="text-start">
                {{ customer.externalRef }}
              </td>
              <td class="text-start">
                {{ customer.name }}
                <br />
                <small>{{ customer.location }}</small>
              </td>
              <td class="text-start">
                {{ customer.address }}
              </td>
              <td class="text-start">
                {{ customer.zipCode }} {{ customer.city }}
              </td>
              <td class="text-center">{{ customer.type }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else>Ingen kunder</p>
      </div>
      <div id="inspection">
        <h4>Inspektioner</h4>
        <table
          class="table table-sm table-hover"
          v-if="singleCustomer && singleCustomer.length > 0"
        >
          <thead>
            <tr>
              <td class="text-start">Sags nr.</td>
              <td class="text-start">Navn</td>
              <td class="text-start">Adresse</td>
              <td class="text-start">Postnr og by</td>
              <td class="text-center">Type</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in singleCustomer" :key="customer.id">
              <td class="text-start">
                {{ customer.externalRef }}
              </td>
              <td class="text-start">
                {{ customer.name }}
                <br />
                <small>{{ customer.location }}</small>
              </td>
              <td class="text-start">
                {{ customer.address }}
              </td>
              <td class="text-start">
                {{ customer.zipCode }} {{ customer.city }}
              </td>
              <td class="text-center">{{ customer.type }}</td>
            </tr>
          </tbody>
        </table>

        <p v-else>Ingen enkelte inspektioner</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import UserViewModel from '@/Model/UserViewModel';
import useUsers from './useUsers';
import { useRoute, useRouter } from 'vue-router';
import toDateString from '@/utillities/toDateString';
import CustomerViewModel from '@/Model/CustomerViewModel';
export default defineComponent({
  setup() {
    const { get, getLocations, deleteUser } = useUsers();
    const { params } = useRoute();
    const user = ref<UserViewModel>();
    const customers = ref<Array<CustomerViewModel>>();
    const groupCustomer = computed<Array<CustomerViewModel>>(() => {
      if (customers.value) {
        return customers.value?.filter(
          (item: CustomerViewModel) => item.customerType == 1
        );
      }
      return [];
    });
    const singleCustomer = computed<Array<CustomerViewModel>>(() => {
      if (customers.value) {
        return customers.value?.filter(
          (item: CustomerViewModel) => item.customerType == 0
        );
      }
      return [];
    });
    get(params.id).then((response: UserViewModel) => {
      user.value = response;
      getLocations(params.id).then((response: Array<CustomerViewModel>) => {
        customers.value = response;
      });
    });

    const router = useRouter();
    const confirmedDeletion = () => {
      return deleteUser(user.value?.id).then(() => router.go(-1));
    };

    return {
      user,
      deleteUser,
      toDateString,
      groupCustomer,
      singleCustomer,
      confirmedDeletion
    };
  }
});
</script>
<style lang="scss" scoped>
.card {
  border: none;
}
</style>
