
import { defineAsyncComponent, defineComponent, onMounted, ref } from 'vue';
import useCustomers from './useCustomers';
import { useRoute } from 'vue-router';
import CustomerViewModel from '@/Model/CustomerViewModel';
export default defineComponent({
  components: {
    Location: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerLokations.vue'
        )
    ),
    Users: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "customers" */ './components/customerUsers.vue'
        )
    )
  },
  setup() {
    const { get } = useCustomers();
    const { params } = useRoute();
    const model = ref<CustomerViewModel>();
    onMounted(() => {
      const id = parseInt(params.id.toString(), 10);
      get(id).then((response: CustomerViewModel) => {
        model.value = response;
      });
    });
    return {
      model
    };
  }
});
